<template>
    <div>
        <div>
            <b-overlay :show="show" rounded="sm" class="mt-2">
                <b-row class="match-height">
                    <b-col md="6">
                        <b-card class="px-2">
                            <div>
                                <b-card-title>
                                    Current Subscription Plan
                                </b-card-title>
                            </div>
                            <div>
                                <b-card-sub-title class="mb-2" v-if="selectedPlan == null">See your Free plan</b-card-sub-title>
                                <b-card-sub-title class="mb-2" v-else>See your Premium plan</b-card-sub-title>
                            </div>
                            <div class="pb-1">
                                <current-subscription-plan :selectedPlan="selectedPlan" />
                            </div>
                            <div>
                                <b-button v-if="isOnGracePeriod" variant="primary" :to="{name: 'change-subscription-plan'}" >Resubscribe to subscription plan</b-button>
                                <b-button v-else variant="primary" :to="{name: 'change-subscription-plan'}">Change subscription plan</b-button>
                            </div>
                        </b-card>
                    </b-col>
                    <b-col md="6">
                        <b-card class="px-2">
                            <div>
                                <b-card-title>
                                    Next Payment
                                </b-card-title>
                            </div>
                            <div>
                                <b-card-sub-title class="mb-2">See your upcoming invoice</b-card-sub-title>
                            </div>
                            <div v-if="upcomingInvoice != 0">
                                <h3>Amount: {{ upcomingInvoice.amount_due / 100 }} {{ upcomingInvoice.currency.toUpperCase() }}</h3>
                                <span>Date: {{ upcomingInvoice.next_payment_attempt | justDate }}</span>
                            </div>
                            <div v-else>
                                <div class="d-flex">
                                    <h3>Amount: 0 GBP</h3>
                                </div>
                            </div>                          
                        </b-card>
                    </b-col>
                </b-row>
            </b-overlay>
        </div>

        <div class="match-height" v-if="selectedPlan != null">
            <b-card class="px-2">
                <div>
                    <b-card-title>
                        Cancel Subscription
                    </b-card-title>
                </div>
                <div class="pb-1">
                    <p class="font-weight-normal">You may cancel your subscription at any time. Once your subscription has been cancelled, you will have the option to resume the subscription until the end of your current billing cycle.</p>
                </div>
                <div>
                    <b-button variant="outline-primary" @click="cancelSubscription">Cancel subscription</b-button>
                </div>
            </b-card>
        </div>

        <b-overlay :show="showReceipts" rounded="sm" class="mt-2">
            <b-row class="match-height">
                <b-col lg="12">
                    <b-card class="px-2">
                        <div>
                            <b-card-title>
                                Receipts
                            </b-card-title>
                        </div>
                        <div>
                            <!-- Table -->
                            <b-table
                                striped
                                hover
                                responsive
                                class="position-relative"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :items="invoices"
                                :fields="fields"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :sort-direction="sortDirection"
                            >
                                <!-- Column: Date -->
                                <template #cell(created)="data">
                                    <span class="font-weight-bold d-block text-nowrap">
                                        {{ data.item.created | timestamp }}
                                    </span>
                                </template>

                                <!-- Column: Number -->
                                <template #cell(number)="data">
                                    <span class="font-weight-bold d-block text-nowrap">
                                        {{ data.item.number }}
                                    </span>
                                </template>

                                <!-- Column: PDF -->
                                <template #cell(invoice_pdf)="data">
                                    <a :href="data.item.invoice_pdf" target="_blank">
                                        <span class="font-weight-bold d-block text-nowrap">
                                            <b-badge variant="primary">
                                                <feather-icon
                                                icon="DownloadIcon"
                                                class="mr-25"
                                                />
                                                <span>PDF</span>
                                            </b-badge>
                                        </span>
                                    </a>
                                </template>
                                
                                <!-- Column: Amount paid -->
                                <template #cell(amount_due)="data">
                                    <a :href="data.item.invoice_pdf" target="_blank">
                                        <span class="font-weight-bold d-block text-nowrap">
                                            {{ data.item.amount_due / 100 }} {{ data.item.currency.toUpperCase() }}
                                        </span>
                                    </a>
                                </template>

                                
                            </b-table>
                            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                                <!-- pagination -->
                                <div>
                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    first-number
                                    last-number
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mb-0"
                                    >
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                    </b-pagination>
                                </div>
                            </b-card-body>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </b-overlay>

        <purchased-questionnaires />

    </div>
</template>

<script>
import EventBus from "../../main.js"
import { 
    BCard, BCardTitle, BCardSubTitle, BRow, BCol, BOverlay, BButton, BTable, BBadge, BPagination, BCardBody,
 } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CurrentSubscriptionPlan from "../../components/payment/CurrentSubscriptionPlan.vue"
import PurchasedQuestionnaires from "../../components/payment/PurchasedQuestionnaires.vue"

export default {
    components: {
        BCard,
        BCardTitle, 
        BCardSubTitle,
        BRow,
        BCol,
        BOverlay,
        BButton,
        BTable,
        BBadge,
        BPagination,
        BCardBody,
        CurrentSubscriptionPlan,
        PurchasedQuestionnaires
    },
    data() {
        return {
            show: false,
            showReceipts: true,
            selectedPlan: null,
            currentSubscriptionPlan: null,
            currentSubscriptionPrice: null,
            upcomingInvoice: 0,
            // Table
            invoices: [],
            perPage: 10,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "created", label: "Created" },
                { key: "number", label: "Number" },
                { key: "invoice_pdf", label: "Invoice" },
                { key: "amount_due", label: "Amount" },
            ],
        }
    },
    computed: {
        isOnGracePeriod() {
            return this.$store.getters["subscriptionStoreModule/isOnGracePeriod"]
        }
    },
    methods: {
        // Get current subscription plan
        getCurrentSubscriptionPlan() {
            this.$http.get('/api/auth/payment/current-plan')
                .then((response) => {
                    if(response != undefined) {
                        console.log(response.data)
                        this.currentSubscriptionPlan = response.data.subscriptions
                        if(this.currentSubscriptionPlan.length == 0) {
                            this.currentSubscriptionPrice = null
                        } else {
                            this.currentSubscriptionPrice = response.data.subscriptions[0].stripe_price
                        }
                        this.selectedPlan = this.currentSubscriptionPrice

                        this.show = false
                    }
                })
                .catch((err) => {
                    console.log(err)

                    this.show = false

                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                })
        },

        getUpcomingInvoice() {
            this.$http.get('/api/auth/payment/upcoming_invoice')
                .then((response) => {
                    if(response != undefined) {
                        this.upcomingInvoice = response.data;
                    }
                })
                .catch((err) => {
                    console.log(err)

                    this.showReceipts = false

                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong with invoices. Please login again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                })
        },
        
        // Get invoices
        getInvoices() {
            this.$http.get('/api/auth/payment/invoices')
                .then((response) => {
                    if(response != undefined) {
                        this.invoices = response.data

                        // Set the initial number of items
                        this.totalRows = this.invoices.length;
                    }
                    
                    this.showReceipts = false
                })
                .catch((err) => {
                    console.log(err)

                    this.showReceipts = false

                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong with invoices. Please login again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                })
        },

        // Cancel subscription
        cancelSubscription() {
            this.$bvModal
                .msgBoxConfirm("You are currently on the premium plan and get full access to all mental health checks and all recommended content to support your mental health. By selecting ‘Cancel subscription’ you will have restricted access to mental health checks and recommendations", {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    // Show overlay
                    this.show = true

                    this.$http.get('/api/auth/payment/unsubscribe')
                        .then((response) => {
                            if(response != undefined) {
                                // Get current plan
                                this.getCurrentSubscriptionPlan()
                                this.show = false

                                // Get fresh Upcoming Invoice
                                this.getUpcomingInvoice()

                                // Refresh Pricing modal
                                EventBus.$emit("getCurrentSubscriptionPlan")
                                
                                // Show toast
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: "CheckSquareIcon",
                                        variant: "success",
                                    },
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            // Hide overlay
                            this.show = false

                            let errorMsg = "Something went wrong. Please try again"
                            
                            if(err.response.data.message != null) {
                                errorMsg = err.response.data.message
                            }

                            // Show toast
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: errorMsg,
                                    icon: "AlertCircleIcon",
                                    variant: "danger",
                                },
                            });
                        })
                }
            })
        },

        getHumanDateFormat(unixDateTime) {
            var date = new Date(unixDateTime);
            return date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear();
        }
    },
    mounted() {
        this.show = true

        // Check grace period
        this.$store.dispatch("subscriptionStoreModule/setGracePeriod")

        this.getCurrentSubscriptionPlan()
        this.getInvoices()
        this.getUpcomingInvoice();
    }
}
</script>
