<template>
    <div>
        <b-overlay :show="show" rounded="sm" class="mt-2">
            <b-row class="match-height">
                <b-col lg="12">
                    <b-card class="px-2">
                        <div>
                            <b-card-title>
                                Purchased Mental Health Checks
                            </b-card-title>
                        </div>
                        <div>
                            <!-- Table -->
                            <b-table
                                striped
                                hover
                                responsive
                                class="position-relative"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :items="data"
                                :fields="fields"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :sort-direction="sortDirection"
                            >
                                <!-- Column: Date -->
                                <template #cell(id)="data">
                                    <router-link :to="{name: 'questionnaire-submissions', params: { 
                                            id: data.item.id,
                                            category: data.item.questionnaire.subcategory.questionnairecategory.name,
                                            questionnaireId: data.item.questionnaire.id
                                        }}">
                                        <span class="font-weight-bold d-block text-nowrap">
                                            Details
                                        </span>
                                    </router-link>
                                </template>

                                <!-- Column: Number -->
                                <template #cell(created_at)="data">
                                    <span class="font-weight-bold d-block text-nowrap">
                                        {{ data.item.created_at | classic }}
                                    </span>
                                </template>

                                <!-- Column: Subcategory -->
                                <template #cell(subcategory)="data">
                                    <span class="font-weight-bold d-block text-nowrap">
                                        {{ data.item.questionnaire.subcategory.name }}
                                    </span>
                                </template>

                                <!-- Column: Category -->
                                <template #cell(category)="data">
                                    <span class="font-weight-bold d-block text-nowrap">
                                        {{ data.item.questionnaire.subcategory.questionnairecategory.name }}
                                    </span>
                                </template>
                                                                
                            </b-table>
                            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                                <!-- pagination -->
                                <div>
                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    first-number
                                    last-number
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mb-0"
                                    >
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                    </b-pagination>
                                </div>
                            </b-card-body>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>

<script>
import { 
    BCard, BCardTitle, BRow, BCol, BOverlay, BTable, BPagination, BCardBody,
 } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BCard,
        BCardTitle, 
        BRow,
        BCol,
        BOverlay,
        BTable,
        BPagination,
        BCardBody,
    },
    data() {
        return {
            show: false,
            data: [],
            // Table
            invoices: [],
            perPage: 10,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: "", 
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "id", label: "View" },
                { key: "created_at", label: "Purchase date" },
                { key: "subcategory", label: "MHC Type" },
                { key: "category", label: "MHC Category" },
            ],
        }
    },
    methods: {
        getPurchasedQuestionnaires() {
            this.$http.get('/api/auth/user/payed/questionnaires')
                .then((response) => {
                    if(response != undefined) {
                        this.show = false
                        this.data = response.data
                    }
                })
                .catch((err) => {
                    console.log(err)
                    // Hide overlay
                    this.show = false

                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                })
        }
    },
    mounted() {
        this.show = true

        this.getPurchasedQuestionnaires()
    }

}
</script>