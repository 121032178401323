<template>
    <div>
        <div v-if="selectedPlan == null" class="pricing-card">
            <h3>Always free</h3>
            <small>
                Get 3 free mental health checks every month
            </small>

            <!-- annual plan -->
            <div class="annual-plan">
                <div class="plan-price mt-2">
                <sup class="font-medium-1 font-weight-bold color-mhc-dark">£ </sup>
                <span class="pricing-basic-value font-weight-bolder color-mhc-dark">0</span>
                <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                </div>
            </div>
            <!--/ annual plan -->

            <!-- plan benefit -->
            <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                >
                    3 free mental health checks per month
                </b-list-group-item>
            </b-list-group>
            <!--/ plan benefit -->
        </div>
        <div v-else-if="selectedPlan == productFullPackage" class="pricing-card">
            <h3>Premium</h3>
            <small>Access to all features</small>

            <!-- annual plan -->
            <div class="annual-plan">
                <div class="plan-price mt-2">
                <sup class="font-medium-1 font-weight-bold color-mhc-dark">£ </sup>
                <span class="pricing-basic-value font-weight-bolder color-mhc-dark">{{ fullPackagePrice }}</span>
                <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                </div>
            </div>
            <!--/ annual plan -->

            <!-- plan benefit -->
            <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                    Unlimited Mental Health Checks
                </b-list-group-item>
            </b-list-group>
            <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                    Unlimited support access
                </b-list-group-item>
            </b-list-group>
            <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                    Full community access
                </b-list-group-item>
            </b-list-group>
            <!--/ plan benefit -->
        </div>
    </div>
</template>

<script>
import store from "@/store/index";
import {  BRow, BCol, BListGroup, BListGroupItem } from "bootstrap-vue";

export default {
    components: {
        BRow,
        BCol,
        BListGroup,
        BListGroupItem,
    },
    props: ['selectedPlan'],
    data() {
        return {
            // Products
            productFullPackage: store.state.app.productFullPackage,
        }
    },
    computed: {
        fullPackagePrice() {
            return this.$store.getters['app/fullPackagePrice']
        }
    },
    mounted() {
        // Get full package price
        this.$store.dispatch('app/getFullPackagePrice')
    }
}
</script>

<style scoped>
    .list-group-item {
        background-color: unset;
    }

    .list-group.list-group-circle .list-group-item:after {
        background-image: url(/check_circle.png);
        background-size: 20px;
    }

    .list-group.list-group-circle .list-group-item:after {
        height: 20px;
        width: 20px;
        margin-top: -6px;
    }
</style>